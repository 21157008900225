<template lang="pug">
  tr.matchings-item
    td.sticky-col.sync.align-top.pt-3
      AppToggle(
        :value="carClass.sync"
        :disabled="!isSelectedCarsAndShops"
        :title="carClass.sync ? $t('car_class_matching.sync_toggle_tooltip') : ''"
        v-b-tooltip.hover
        @change="changeCarClassData('sync', $event.value)"
      )
    td
      .item-name.h-100.pt-3(v-if="!isDraftMatching")
        | {{ currentShop.name }}
      .item-name-dropdown.h-100(v-else)
        AppDropdown(
          z-index="103"
          searchable
          allow-empty
          close-on-select
          :placeholder="$t('shops_sources_car_matching.select_shop')"
          :value="currentShop"
          :items="shops"
          @select="onSelectShop"
        )
    td.dp.align-top(v-if="currentOrganization.dp")
      AppCheckbox.highlight-checked(
        :value="carClass.dp"
        :disabled="!isSelectedCarsAndShops || !isDraftMatching"
        @change="changeCarClassDp($event)"
      )
    td.inbound.align-top(v-if="currentOrganization.inbound")
      AppCheckbox.highlight-checked(
        :value="carClass.inbound"
        :disabled="!isSelectedCarsAndShops"
        @change="changeCarClassData('inbound', $event)"
      )
    td.car-row.h-100.align-top
      CarMatchingItem(
        :item="item"
        :sources-cars="sourcesCars"
        :selected-sources-cars="selectedSourcesCars"
        :current-shop="currentShop"
        :is-selected-shop="isSelectedShop"
        :is-selected-car="isSelectedCar"
        :is-draft-matching="isDraftMatching"
        :add-selected-sources-car="addSelectedSourcesCar"
        :remove-selected-sources-car="removeSelectedSourcesCar"
        @update-item="$emit('update-item', $event)"
        @remove-sources-car-classes="removeSelectedSourcesCarClasses()"
      )
    td.item-name-dropdown
      CarClassMatchingItem(
        :disabled="!isSelectedCarsAndShops"
        :item="carClass"
        :index="index"
        :is-item-opened="item.isOpened"
        :ota-list="otasWithoutNicolas"
        :sources-items-by-ota-id="sourcesCarClassesByOtaId"
        :selected-sources-items-by-ota-id="selectedSourcesCarClasses"
        :add-selected-sources-item="addSelectedSourcesCarClass"
        :remove-selected-sources-item="removeSelectedSourcesCarClass"
        :immutable-ota-ids="carClass.immutable_ota_ids"
        :invalid-item-ids="invalidItemIds"
        :sources-car-classes-loading="sourcesCarClassesLoading"
        :update-item="updateCarClass"
      )
    td
      shops-sources-actions(
        :item="item"
        :is-selected-cars-and-shops="isSelectedCarsAndShops"
        @delete-item="deleteItem"
        @toggle-item-open="toggleItemVisibility"
      )
</template>

<script>
  // mixins
  import withPermissions from "@/mixins/withPermissions"

  // misc
  import { mapGetters } from "vuex"

  export default {
    props: {
      item: Object,
      index: Number,
      shops: Array,
      addSelectedSourcesCarClass: Function,
      removeSelectedSourcesCarClass: Function,
      addSelectedSourcesCar: Function,
      removeSelectedSourcesCar: Function,
      sourcesCarClassesByOtaId: Object,
      selectedSourcesCars: Object,
      selectedSourcesCarClasses: Object,
      sourcesCars: Array,
      sourcesCarClassesLoading: Boolean,
      invalidItemIds: {
        type: Array,
        default: () => new Array()
      }
    },

    mixins: [withPermissions],

    components: {
      CarClassMatchingItem: () => import("./CarClassMatchingItem"),
      CarMatchingItem: () => import("./CarMatchingItem"),
      ShopsSourcesActions: () => import("./ShopsSourcesActions"),
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox")
    },

    computed: {
      ...mapGetters(["otasWithoutNicolas", "currentOrganization"]),

      carClass({ item }) {
        return item.car_class
      },

      currentShop({ item }) {
        return item.shop
      },

      isDraftMatching({ item }) {
        return !!item._addedAt
      },

      isSelectedCar({ item }) {
        return !!item.id
      },

      isSelectedShop({ currentShop }) {
        return !!currentShop.id
      },

      isSelectedCarsAndShops({ isSelectedCar, isSelectedShop }) {
        return isSelectedCar && isSelectedShop
      }
    },

    methods: {
      changeCarClassDp(value) {
        this.removeSelectedSourcesCarClasses()

        this.$emit("update-item", {
          ...this.item,
          car_class: { ...this.item.car_class, dp: value, sources_car_classes: [] }
        })
      },

      changeCarClassData(field, value) {
        this.$emit("update-item", {
          ...this.item,
          car_class: { ...this.item.car_class, [field]: value }
        })
      },

      onSelectShop(value) {
        if (value.id === this.currentShop.id) return

        if (this.isSelectedShop) {
          this.removeSelectedSourcesCar(this.item)
          this.removeSelectedSourcesCarClasses()
        }

        this.$emit("update-item", {
          ...this.item,
          id: null,
          name: null,
          ota_id: null,
          expired: false,
          active: true,
          inactive: false,
          shop: { ...value },
          car_class: { ...this.item.car_class, name: null, sources_car_classes: [] }
        })
      },

      toggleItemVisibility() {
        this.$emit("update-item", { ...this.item, isOpened: !this.item.isOpened })
      },

      updateCarClass({ item }) {
        this.$emit("update-item", {
          ...this.item,
          car_class: { ...this.item.car_class, ...item }
        })
      },

      deleteItem() {
        this.removeSelectedSourcesCar(this.item)
        this.removeSelectedSourcesCarClasses()

        this.$emit("delete-item")
      },

      removeSelectedSourcesCarClasses() {
        this.carClass.sources_car_classes.forEach(sourcesItem => this.removeSelectedSourcesCarClass(sourcesItem))
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/matchings.sass"

  .matchings-item
    height: 50px

    .car-row
      width: 27em
      max-width: 27em
    td
      +matchings-item-td

      &.item-name
        width: 30%

      &.selected-quantity
        width: 6%

      &.dp, &.inbound
        padding-top: 0.75rem
</style>
